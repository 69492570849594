import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="active first overview last">
                <a href="/contact/"> Contact</a>
              </li>
            </ul>
          </div>
          <div className="page nine columns page-36">
            <h1>Contact</h1>
            <p>
              <strong>Johnson &amp; Johnson Consumer B.V.</strong>
            </p>
            <p>
              <strong>
                Via{" "}
                <a
                  href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p2"
                  target="_blank"
                >
                  contactformulier
                </a>{" "}
              </strong>
            </p>
            <p>
              <strong>Via brief:</strong>
              <br />
              Johnson &amp; Johnson Consumer B.V.
            <br />
            Consumentenservice
            <br />
             Stadsplateau 27-29
              <br />
              3521 AZ Utrecht
              <br />
              Nederland
            </p>
            <p>
              <strong>Gratis servicelijn</strong>
              <br />
              Via e-mail: consumer-nl@kenvue.com
              <br />
              Onze servicelijn is geopend van maandag t/m vrijdag van 09.00 tot
              16.00 uur om je vragen te beantwoorden.
            </p>
            <p>
              U gaat ermee akkoord dat de informatie die u geeft onder ons{" "}
              <a href="/privacybeleid/">Privacybeleid</a> valt.
            </p>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <div
            className="flash-wrapper"
            style={{
              "max-width": "100%",
              height: "236px",
              width: "428px",
              margin: "0 auto"
            }}
          >
            <div id="flash">
              <p>
                Your browser must have Adobe Flash installed to see this
                animation.
              </p>
            </div>
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
         <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script src="/assets/js/custom.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
         
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\ncookiesDirective('bottom',5,'/cookiebeleid');\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
